import { ref, onMounted, onUnmounted } from 'vue-demi'

export default function useScroll() {
  const scrollY = ref(0)
  const isOnTop = ref(true)

  function updateScroll() {
    scrollY.value = window.scrollY
    isOnTop.value = window.scrollY === 0
  }

  onMounted(() => {
    window.addEventListener('scroll', updateScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', updateScroll)
  })

  return { scrollY, isOnTop }
}
