<template>
  <div :class="$style.wrapper">
    <svg :width="SIZE" :height="SIZE" :class="$style.svg">
      <circle
        :cx="HALF"
        :cy="HALF"
        :r="R"
        :class="$style.incompleteCircle"
        stroke="#F1F3F5"
        :stroke-width="CHART_WIDTH"
        fill="transparent"
      />

      <path
        :d="path"
        :class="$style.completePath"
        :stroke-width="CHART_WIDTH"
        stroke="#9bcbeb"
        fill="transparent"
      />
    </svg>

    <div :class="$style.content">
      <Typography :variant="TYPOGRAPHY_TYPES.h4">{{ percentage }}%</Typography>
    </div>
  </div>
</template>

<script>
import { normalize } from '@front/common/utils'
import { Typography, TYPOGRAPHY_TYPES } from '@front/ui'
import { computed, defineComponent, toRefs } from 'vue-demi'

import { getPositionFromAngle } from './utils'

const SIZE = 110
const CHART_WIDTH = 16 // Épaisseur du tracé
const HALF = SIZE / 2
const R = HALF - CHART_WIDTH * 0.5
const ORIGIN = { x: HALF, y: HALF }

export default defineComponent({
  components: { Typography },
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { percentage } = toRefs(props)

    const startingPoint = getPositionFromAngle(HALF, HALF, R, 0)

    const angle = computed(() => {
      return normalize(percentage.value, [0, 100], [0, 360])
    })

    const path = computed(() => {
      return `
        M ${startingPoint.x} ${startingPoint.y}
        A ${R} ${R} 0 ${angle.value > 180 ? 1 : 0} 1 
        ${getPositionFromAngle(ORIGIN.x, ORIGIN.y, R, angle.value).x} 
        ${getPositionFromAngle(ORIGIN.x, ORIGIN.y, R, angle.value).y}
      `
    })

    return {
      SIZE,
      HALF,
      R,
      CHART_WIDTH,
      path,
      TYPOGRAPHY_TYPES,
    }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.svg {
  z-index: 0;
  transform: rotate(-90deg);
}

.incompleteCircle {
  stroke-linecap: round;
}

.completePath {
  stroke-linecap: round;
  transition: stroke-dashoffset 1s ease;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
