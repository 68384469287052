<template>
  <div :class="$style.wrapper">
    <Popper
      :class="$style.navigation"
      :with-arrow="false"
      is-content-full-width
    >
      <template #trigger="{ isVisible }">
        <Icon :name="getMenuIcon(isVisible)" height="32px" width="32px" />
      </template>

      <template #content>
        <div :class="$style.menu">
          <Navigation :links="links" />

          <Link
            :class="$style.link"
            :child-class="$style.userButtonLinkChildClass"
            :link="memberAreaLink"
          >
            <Button :class="$style.button">{{ $i18n('Se connecter') }}</Button>
          </Link>
        </div>
      </template>
    </Popper>

    <Logo :class="[$style.logo, { [$style.isHidden]: !isOnTop }]" />

    <Link
      :link="SHOWCASE_ROUTE_PROFILE_BUILDER"
      :class="[$style.profileBuilderButton, { [$style.isHidden]: isOnTop }]"
    >
      <Button>{{ $i18n('Je commande') }}</Button>
    </Link>

    <Link
      :class="$style.link"
      :child-class="$style.userLinkChildClass"
      :link="memberAreaLink"
    >
      <Icon name="user" :class="$style.userIcon" height="32px" width="32px" />
    </Link>
  </div>
</template>

<script>
import { Logo } from '@front/common/components'
import useScroll from '@front/common/hooks/useScroll'
import { Button, Icon, Popper } from '@front/ui'
import { computed, defineComponent } from '@vue/composition-api'

import Link from '@showcase/components/Link.vue'
import { SHOWCASE_ROUTE_PROFILE_BUILDER } from '@showcase/core/routes-names'

import Navigation from './components/Navigation.vue'

export default defineComponent({
  components: {
    Button,
    Logo,
    Icon,
    Popper,
    Link,
    Navigation,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { isOnTop } = useScroll()

    const memberAreaLink = computed(() => {
      return process.env.SNOOPY_URL
    })

    function getMenuIcon(isVisible) {
      return isVisible ? 'xmark' : 'menu'
    }

    return {
      getMenuIcon,
      memberAreaLink,
      isOnTop,
      SHOWCASE_ROUTE_PROFILE_BUILDER,
    }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  padding: $ds-spacing-mobile-extra-small 0px;
}

.navigation {
  flex: 1;
}

.logo {
  width: 116px;
  height: 58px;
  flex: 1;
  opacity: 1;
  transition: 0.6s opacity ease;

  &.isHidden {
    transition: none;
    opacity: 0;
    flex: 0;
    width: 0px;
  }
}

.profileBuilderButton {
  opacity: 1;
  transition: 0.6s opacity ease;

  &.isHidden {
    transition: none;
    width: 0px;
    opacity: 0;

    * {
      width: 0px;
      min-width: 0px;
    }
  }
}

.link {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.menu {
  display: flex;
  flex-direction: column;
  padding: $ds-spacing-mobile-small $ds-spacing-mobile-extra-small;
}

.label {
  margin-top: $ds-spacing-mobile-extra-small;
  text-transform: uppercase;
}

.userLinkChildClass {
  color: black;
  display: flex;
}

.userButtonLinkChildClass {
  width: 100%;
  color: black;
  margin: 0px auto;
}

.userIcon {
  border: 1.5px solid #000;
  border-radius: 50%;
}

.button {
  width: 100%;
  margin-top: $ds-spacing-mobile-small;
}
</style>
