var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper,on:{"click":_vm.handleClick}},[_c('TwicImg',{class:_vm.$style.image,attrs:{"src":_vm.imageUrl,"ratio":"none","focus":"auto"}}),_c('div',{class:_vm.$style.content},[_c('Typography',{class:_vm.$style.title,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h5,"tag":"h4"}},[_vm._v("\n      "+_vm._s(_vm.truncatedTitle)+"\n    ")]),_c('Typography',{class:_vm.$style.subTitle,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n      "+_vm._s(_vm.product.metaInformation.subTitle)+"\n    ")]),_c('div',{class:_vm.$style.bottomContent},[_c('Label',[_vm._v("\n        "+_vm._s(_vm.$i18n(
            "{quantity, plural,\n              =1 {}\n              other {# x}\n            } {weight}",
            {
              quantity: _vm.product.article.pcb,
              weight: _vm.formatQuantity(
                _vm.product.metaInformation.capacity.quantity,
                { unit: _vm.product.metaInformation.capacity.unit }
              ),
            }
          ))+"\n      ")]),_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h5}},[_vm._v("\n        "+_vm._s(_vm.formatPrice(_vm.product.price.amount))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }