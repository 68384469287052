var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-press--wrapper"},[_c('div',{staticClass:"ce-press--marquee"},[_c('Slider',{staticClass:"ce-press--content",attrs:{"disable-arrows":"","disable-dots":"","options":{
        perView: 1,
        peak: 1,
        type: 'carousel',
        animationDuration: 12000,
        animationTimingFunc: 'linear',
        direction: 'rtl',
        autoplay: 1,
        breakpoints: {
          500: {
            perView: 0.3,
          },
          1112: {
            perView: 0.5,
          },
        },
      }}},[_c('div',{staticClass:"ce-press--logo-container"},[_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1ztdeNN25Ogt_FJPZvl0z7lHl-RuzPIPq&twic=v1/contain=-x70","alt":"BFM business logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zrl0MrKXYDuABmgGBOVxtsGYOGOpVSOX&twic=v1/contain=-x70","alt":"ELLE logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zlxG3uETh6tuT_piq6epjxUuUNo4QV02&twic=v1/contain=-x70","alt":"France 2 logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zlSlphYB-QoGxHH8LiELfvplH_BpEv51&twic=v1/contain=-x70","alt":"La Provence logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1ziQ0ey3zoFMBAz0-Qmz3xtutUgnc3y5k&twic=v1/contain=-x70","alt":"Le Figaro logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zh8Qm97Fo5-R7hk8By9UHjbN4JoNC3h1&twic=v1/contain=-x70","alt":"Les Echos logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zb4K6V3dfhKucJ99W9zs-jaTQaxojIzI&twic=v1/contain=-x70","alt":"Tf1 logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zYJvvJraXPIGFMuWVG9D0B5JggHuYUQp&twic=v1/contain=-x70","alt":"RTL logo"}}),_c('img',{staticClass:"ce-press--logo",attrs:{"src":"https://japhy-assets.twic.pics/download?id=1zeatvtRfYG6u0TKxpV0g5Zg4dKbe6f1k&twic=v1/contain=-x70","alt":"TELE matin logo"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }